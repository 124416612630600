import React from "react";

import Layout from "@layout/index";
import Header from "@/components/head";

import {
  Text,
  List,
  ListItem,
  Heading,
  Link as ChakraLink,
  VStack,
  Center,
} from "@chakra-ui/react";
import customStyles from "@/assets/theme/customStyles";

const { memo } = React;

export const Head = () => {
  return (
    <Header
      title="Airgram Terms of Service | Data Security is Our Top Priority"
      description="Airgram's Terms of Service. We put users' privacy and data security first. GDPR, CCPA and APPI compliant. SSL encrypted."
    />
  );
};

const TermsPage: React.FC = () => {
  return (
    <Layout navMaxW={customStyles.web_max_w}>
      <Center w="full">
        <VStack pb="40px" maxW={customStyles.pure_text_max_w}>
          <Heading as="h1" fontSize={24} fontWeight={600} textAlign="center">
            Terms of Service
          </Heading>
          <Text textAlign="center">Last update on April 2, 2021</Text>

          <Text py="24px">
            Thank you for visiting the Airgram for web application and platforms
            (the 'Platform') provided by Airgram Inc. (the websites include
            <ChakraLink mx="5px" color="#7d848e" href="https://www.airgram.io">
              https://www.airgram.io
            </ChakraLink>
            ,
            <ChakraLink mx="5px" color="#7d848e" href="https://app.airgram.io">
              https://app.airgram.io
            </ChakraLink>
            ). By accessing or using the Platform or any other Airgram products,
            software, and services provided to you on, from or through the
            Platform (collectively the 'Services'), whether automated or
            otherwise, you ('Customer') agree to be bound by these terms of
            service, the Airgram Privacy Policy and any additional terms and
            conditions that are referenced below or otherwise may apply to
            specific areas of the Platform ('Terms' or 'Agreement'). Please read
            these Terms carefully before using the Services. If you do not agree
            to be bound by any of these Terms, you may not use the Services.
          </Text>

          <Text as="h4">Acceptance of Agreement</Text>

          <Text>
            These Terms govern your access and use of the Services offered by
            Airgram and any information displayed on the Platform. By using the
            Services, you are indicating your acceptance of this Agreement,
            which thereby becomes a binding contract between you and Airgram.
            You represent that you are legally able to accept these Terms, and
            affirm that you are of legal age to form a binding contract.
            Airgram’s acceptance is expressly conditioned upon your assent to
            all the terms and conditions of this Agreement. The Services are not
            intended for and should not be used by anyone under the age of 18.
            By using the Services, you represent and warrant that you meet the
            foregoing eligibility requirement.
          </Text>

          <Text>
            You represent that you are legally able to accept these Terms, and
            affirm that you are of legal age to form a binding contract.
            Airgram’s acceptance is expressly conditioned upon your assent to
            all the terms and conditions of this Agreement. The Services are not
            intended for and should not be used by anyone under the age of 18.
            By using the Services, you represent and warrant that you meet the
            foregoing eligibility requirement.
          </Text>

          <Text>
            The terms 'you', 'your', 'yourself' shall also include your
            employees, agents, business representatives and any other
            individuals that you provide access to the Services through your
            Account (as defined below). You are responsible for ensuring that
            all persons who access the Services through your account are aware
            of these Terms and comply with them.
          </Text>
          <Text>
            Airgram reserves the right to revise and update these Terms from
            time to time in its sole discretion. All changes are effective
            immediately when posted. Your continued use of the Services
            following the posting of revised Terms means that you accept and
            agree to the changes. You are expected to check this page from time
            to time so you are aware of any changes, as they are binding on you.
          </Text>

          <Text as="h4">Use of the Services</Text>

          <Text>
            Our Services uses proprietary speech and speaker recognition
            technology to transform voice conversations into legible text. The
            service also enables you to share associated transcripts with
            others. We will make the Services available to you. You are
            responsible for making all arrangements necessary for you to have
            access to the Services, including the high-speed internet.
          </Text>

          <Text as="h4">Rules of Conduct</Text>
          <Text as="strong" w="full" fontSize="18px" fontWeight={700}>
            1. There are rules of conduct that you must follow when you use the
            Services. You must not:
          </Text>

          <List>
            <ListItem>
              1.1 Use the Services in any way that violates any applicable
              federal, state, local, or international law or regulation;
            </ListItem>
            <ListItem>
              1.2 Infringe upon or violate Airgram’s intellectual property
              rights or the intellectual property rights of others;
            </ListItem>
            <ListItem>
              1.3 'Harvest' (or collect) information from the Platform using an
              automated software tool or manually on a mass basis (unless we
              have given you separate written permission to do so);
            </ListItem>
            <ListItem>
              1.4 Use automated means to access the Platform, or gain
              unauthorized access to the Platform or to any account or computer
              system connected to the Platform;
            </ListItem>
            <ListItem>
              1.5 Obtain, or attempt to obtain, unauthorized access to areas of
              the Platform or our systems;
            </ListItem>
            <ListItem>
              1.6 'Flood' the Platform with requests or use the Services in any
              manner that could disable, overburden, damage, or impair the
              Platform;
            </ListItem>
            <ListItem>
              1.7 Circumvent or reverse engineer the Platform or its systems;
            </ListItem>
            <ListItem>
              1.8 Manipulate or forge identifiers in order to disguise the
              origin of any information posted on the Platform or otherwise
              provided to us or our employees;
            </ListItem>
            <ListItem>
              1.9 Impersonate or attempt to impersonate Airgram, a Airgram
              employee, another user, or any other person or entity;
            </ListItem>
            <ListItem>
              1.10 Introduce any viruses, trojan horses, worms, logic bombs, or
              other material that is malicious or technologically harmful;
            </ListItem>
            <ListItem>
              1.11 Spam, phish, pharm, pretext, spider, crawl, or scrape;
            </ListItem>
            <ListItem>
              1.12 Engage in any other conduct that interfere with anyone’s use
              or enjoyment of the Services, or which, as determined by us, may
              harm Airgram or users of the Services or expose them to liability.
            </ListItem>
          </List>

          <Text fontSize="18px" as="strong" w="full" fontWeight={700}>
            2. You and others that you record are solely responsible for any
            content that becomes part of the Customer Data (as defined below).
            If you record through or upload to the Platform materials,
            recordings, videos, pictures, or files (‘Customer Data’), you
            affirm, represent, and warrant that any processing of such Customer
            Data by Airgram will not violate any applicable laws or regulations,
            and that such content shall not, and you will not upload, transmit,
            or contribute anything that:
          </Text>

          <List>
            <ListItem>
              2.1 Contains any material that is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable;
            </ListItem>

            <ListItem>
              2.2 Promotes sexually explicit pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age;
            </ListItem>

            <ListItem>
              2.3 Infringes any patent, trademark, trade secret, copyright, or
              other intellectual property or other rights of any other person;
            </ListItem>
            <ListItem>
              2.4 Violates the legal rights (including the rights of publicity
              and privacy) of others or contains any material that could give
              rise to any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              and our Privacy Policy;
            </ListItem>
            <ListItem>
              2.5 Promotes any illegal activity, or advocates, promotes, or
              assists any unlawful act;
            </ListItem>
            <ListItem>
              2.6 Causes annoyance, inconvenience, or needless anxiety or be
              likely to upset, embarrass, alarm, or annoy any other person;
            </ListItem>
            <ListItem>
              2.7 Contains any virus, malware, spyware or other harmful content
              or code, or materials that otherwise violate this Agreement.
            </ListItem>
          </List>

          <Text fontSize="18px" as="strong" w="full" fontWeight={700}>
            3. The Services provide a feature that allows you to record
            individual conversations and/or upload recorded conversations. The
            laws regarding the notice and notification requirements of such
            recorded conversations vary by location. It may be an offense in
            your jurisdiction to record other individuals without their prior
            written consent. It is your responsibility to follow applicable
            foreign, federal and local laws when using such feature, including
            informing the participants in your meetings that the discussion is
            being recorded. We absolutely do not condone the use of our Services
            for illegal purposes.
          </Text>
          <Text fontSize="18px" as="strong" w="full" fontWeight={700}>
            4. The use of the Services is done at your own discretion and risk
            and with the agreement that you will be solely responsible for
            Customer Data and the consequences of recording, submitting and
            publishing Customer Data on or through the Services.
          </Text>

          <Text as="h4">Monitoring and Enforcement</Text>

          <Text>
            We may review conduct for compliance purposes. We are not
            responsible for the content of any of your materials, recordings,
            videos, pictures, data, information, content, or files ('Customer
            Data') or the way you choose to store or process any Customer Data
            on the Platform. We reserve the right to investigate and take
            appropriate legal action in our sole discretion against anyone who
            violates these Terms. We have the right to:
          </Text>

          <List>
            <ListItem>1. Remove any Customer Data;</ListItem>

            <ListItem>
              2. Take any action with respect to any Customer Data that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such Customer Data violates the Terms, infringes any
              intellectual property right or other right of any person or
              entity, threatens the personal safety of users or the public, or
              could create liability for Airgram;
            </ListItem>

            <ListItem>
              3. Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Services;
            </ListItem>
            <ListItem>
              4. Terminate or suspend your access to all or part of the Services
              for any reason, including without limitation, any violation of
              these Terms.
            </ListItem>
          </List>

          <Text>
            Without limiting the foregoing, we have the right to cooperate with
            any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            posting any materials on or through the Platform. YOU WAIVE AND HOLD
            HARMLESS Airgram AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS
            FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE
            FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
            INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
            AUTHORITIES.
          </Text>

          <Text>
            However, we do not undertake to review any material before it is
            uploaded to or posted on the Platform, and cannot ensure prompt
            removal of objectionable material. Accordingly, we assume no
            liability for any action or inaction regarding transmissions,
            communications, or content provided by any user or third party. We
            have no liability or responsibility to anyone for performance or
            nonperformance of the activities described in this section.
          </Text>

          <Text as="h4">Ownership</Text>

          <Text fontSize="18px" as="strong" w="full" fontWeight={700}>
            1. Airgram materials
          </Text>

          <Text>
            The Services, including the Platform and its entire contents,
            features, and functionality (including, but not limited to, all
            information, software, text, displays, images, video, and audio, and
            the design, selection, and arrangement thereof), are owned by
            Airgram, its affiliates, licensors, or other providers of such
            material and are protected by international copyright, trademark,
            patent, trade secret, and other intellectual property or proprietary
            rights laws. Airgram, its affiliates and licensors are and shall
            continue to be the sole and exclusive owner of all right, title and
            interest in and to all intellectual property rights associated with
            the Services. You must not modify, copy, reproduce, republish,
            upload, post, transmit, translate, sell, create derivative works,
            exploit, or distribute in any manner or medium (including by email
            or other electronic means) any material from the Platform or the
            Services unless explicitly authorized in these Terms or by the
            owner(s) of the materials.
          </Text>

          <Text>
            We grant to Customer a non-sublicensable, non-transferable,
            non-exclusive, limited license to use the Services in accordance
            with this Agreement and its intended purposes only. Except for
            allowing you to use the Services as set forth herein, you are not
            receiving any other license or any other rights, including
            intellectual property or other proprietary rights of Airgram. You
            understand that you have no rights to the Platform or Services or
            any other Airgram property except as indicated in these Terms. All
            of our rights not expressly granted by this license are hereby
            retained.
          </Text>

          <Text fontSize="18px" as="strong" w="full" fontWeight={700}>
            2. Customer materials
          </Text>

          <Text>
            Customer retains all ownership rights in Customer Data. However, by
            transmitting, uploading or posting Customer Data on or through the
            Services, you hereby grant Airgram a worldwide, non-exclusive,
            royalty-free, license to access, process, copy, export, and display
            Customer Data, only as reasonably necessary (a) to provide, maintain
            and update the Services; (b) to prevent or address service,
            security, support or technical issues; (c) as required by law; and
            (d) as expressly permitted in writing by Customer. Customer
            represents and warrants that it has secured all rights in and to
            Customer Data as may be necessary to grant this license.
          </Text>

          <Text fontSize="18px" as="strong" w="full" fontWeight={700}>
            3. Feedback
          </Text>

          <Text>
            We always appreciate your feedback or other suggestions about the
            Services. If you send us any feedback or suggestions regarding the
            Services, we may or may not use it. Customer grants us an unlimited,
            irrevocable, perpetual, sublicensable, transferable, royalty-free
            license to use any such feedback or suggestions for any purpose
            without any obligation or compensation to Customer.
          </Text>

          <Text as="h4">Registration and Account Security</Text>

          <Text>
            To use certain features or areas of the Services, you will be
            required to provide personal and/or demographic information as part
            of a registration or login process and to create an account
            ('Account'). In addition, certain features of the Services are only
            available to our registered users, and to access those areas of the
            Services you will be required to log in using your username and
            password. In connection with such registration, Airgram may refuse
            to grant you, and you may not use, a username (or email address) or
            screen name that is already being used by someone else, that may be
            construed as impersonating another person, that belongs to another
            person, that violates the intellectual property or other rights of
            any person, that is offensive, or that Airgram rejects for any other
            reason in its sole discretion. You agree to provide true, accurate,
            current and complete information about yourself as prompted by the
            applicable registration or login form, and you are responsible for
            keeping such information up-to-date (this includes your contact
            information, so that we can reliably contact you). The information
            you submit must describe you (you may not impersonate another person
            or entity), and you may not sell, share or otherwise transfer your
            account information. If you provide any information that is untrue
            or inaccurate, not current, or incomplete, or if Airgram suspects
            that your information is untrue or inaccurate, not current, or
            incomplete, Airgram may, in its sole discretion, suspend or
            terminate your right to access any material for which registration
            is required. Any personally identifiable information supplied
            hereunder will be subject to the terms of the Airgram Privacy
            Policy.
          </Text>

          <Text>
            If you choose, or are provided with, a user name, password, or any
            other piece of information as part of our security procedures, you
            must treat such information as confidential. You are responsible for
            all activity occurring when the Services are accessed through your
            account, whether authorized by you or not. We are not liable for any
            loss or damage arising from your failure to protect your password or
            Account information. You agree to notify us immediately of any
            unauthorized access to or use of your user name or password or any
            other breach of security. You should use particular caution when
            accessing your account from a public or shared computer so that
            others are not able to view or record your password or other
            personal information.
          </Text>

          <Text>
            We have the right to disable any user name, password, or other
            identifier, whether chosen by you or provided by us, at any time in
            our sole discretion for any or no reason, including if, in our
            opinion, you have violated any provision of these Terms.
          </Text>

          <Text as="h4">Payment Terms</Text>

          <Text>
            Certain of our Services are subject to payments now (the 'Paid
            Services'). Please note that any payment terms presented to you in
            the process of using or signing up for Paid Services are deemed part
            of this Agreement.
          </Text>

          <Text>
            We may use a third-party payment processor (the 'Payment Processor')
            to bill you through a payment account linked to your Account (your
            'Billing Account').
          </Text>

          <Text>
            We may use a third-party payment processor (the 'Payment Processor')
            to bill you through a payment account linked to your Account (your
            'Billing Account'). The processing of payments will be subject to
            the terms, conditions and privacy policies of the Payment Processor
            in addition to this Agreement. We are not responsible for errors by
            the Payment Processor. By utilizing the Services, you agree to pay
            us, through the Payment Processor, in accordance with the applicable
            payment terms. Airgram reserves the right to change its prices and
            to offer discounts and temporary promotions. You agree that it is
            your responsibility to maintain a valid, non-expired credit card on
            file with us while engaging in fee-based activities on our Services.
          </Text>

          <Text as="h4">Subscription Service</Text>

          <Text>
            Subscription ServiceThe Service may include automatically recurring
            payments for periodic charges (“Subscription Service”). If you
            activate a Subscription Service, you authorize Airgram.io or its
            third party payment processors or Apple or Google if you subscribed
            directly through the mobile application to periodically charge, on a
            going-forward basis and until cancellation of either the recurring
            payments or your account, all accrued sums on or before the payment
            due date for the accrued sums, including all accrued sums for your
            Authorized Users. The “Subscription Billing Date” is the date when
            you purchase your first subscription to the Service. For more
            information on the “Subscription Fee”, please see
            <ChakraLink
              mx="5px"
              color="#7d848e"
              href="https://www.airgram.io/pricing"
            >
              https://www.airgram.io/pricing
            </ChakraLink>
            . Your account will be charged automatically on the Subscription
            Billing Date all applicable fees and taxes for the next subscription
            period, which may be one month or one year depending on the
            selection you make on your account (such period, the “Initial
            Subscription Period”). At the end of the Initial Subscription
            Period, your subscription will automatically renew for a period
            equal to the Initial Subscription Period (such renewal period, the
            “Renewal Subscription Period”), unless you cancel your subscription
            or we terminate it. You must cancel your subscription before it
            renews in order to avoid billing of the next periodic Subscription
            Fee to your account. Airgram.io or its third party payment processor
            will bill the periodic Subscription Fee to the payment method you
            provide to us during registration (or to a different payment method
            if you change your payment information). You may cancel the
            Subscription Service in the settings page for your account at
            Airgram.io or contacting us at:
            <ChakraLink
              mx="5px"
              color="#7d848e"
              href="mailto:support@airgram.io"
            >
              support@airgram.io
            </ChakraLink>
            .
          </Text>

          <Text as="h4">Term and Termination</Text>

          <Text>
            You may stop using our Services at any time. We may terminate or
            suspend your access to or ability to use any and all Services at any
            time, without prior notice or liability, for any reason or no
            reason. In particular, Airgram may immediately terminate or suspend
            your Account if you are in breach of these Terms or if your Account
            has been flagged for copyright infringement. Upon termination, we
            will refund you any prepaid fees covering the remainder of the term
            of all subscriptions after the effective date of termination.
            Termination of your access to and use of the Services shall not
            relieve you of any obligations arising or accruing prior to such
            termination or limit any liability that you otherwise may have to
            Airgram or any third party.
          </Text>

          <Text as="h4">Warranty Disclaimer</Text>

          <Text>
            YOUR USE OF THE SERVICES, THE PLATFORM, AND ANY CONTENT OR PRODUCTS
            THEREIN IS AT YOUR OWN RISK. THE SERVICES, THE PLATFORM, AND ANY
            CONTENT OR PRODUCTS THEREIN ARE PROVIDED ON AN 'AS IS' AND 'AS
            AVAILABLE' BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS
            OR IMPLIED. NEITHER Airgram NOR ANY PERSON ASSOCIATED WITH Airgram
            MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
            AVAILABILITY OF THE SERVICES OR THE PLATFORM. WITHOUT LIMITING THE
            FOREGOING, NEITHER Airgram NOR ANYONE ASSOCIATED WITH Airgram
            REPRESENTS OR WARRANTS THAT THE SERVICES, THE PLATFORM, AND ANY
            CONTENT OR PRODUCTS THEREIN WILL BE ACCURATE, RELIABLE, ERROR-FREE,
            OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR
            THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS, OR THAT THE SERVICES, THE PLATFORM, AND ANY
            CONTENT OR PRODUCTS THEREIN WILL OTHERWISE MEET YOUR NEEDS OR
            EXPECTATIONS. TO THE FULLEST EXTENT PROVIDED BY LAW, Airgram HEREBY
            DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES OR GUARANTEES OF
            ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT
            NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT
            AND FITNESS FOR A PARTICULAR PURPOSE.
          </Text>

          <Text>
            Airgram MAY ALTER, SUSPEND, ADD TO, OR DISCONTINUE THE SERVICES IN
            WHOLE OR IN PART AT ANY TIME FOR ANY REASON, WITHOUT NOTICE OR COST.
            Airgram ASSUMES NO RESPONSIBILITY FOR YOUR ABILITY TO (OR ANY COSTS
            OR FEES ASSOCIATED WITH YOUR ABILITY TO) OBTAIN ACCESS TO THE
            SERVICES. Airgram DOES NOT ASSUME ANY LIABILITY FOR THE FAILURE TO
            STORE OR MAINTAIN ANY CUSTOMER DATA, USER COMMUNICATIONS, ACCOUNT
            INFORMATION, OR PERSONAL SETTINGS. THE SERVICES MAY BECOME
            UNAVAILABLE DUE TO MAINTENANCE OR MALFUNCTION OF COMPUTER EQUIPMENT,
            SERVERS, OR OTHER REASONS.
          </Text>

          <Text>
            YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN
            OR MADE IN RELIANCE ON THE SERVICES OR THE INFORMATION CONTAINED
            THEREIN, INCLUDING INACCURATE OR INCOMPLETE INFORMATION. YOU AGREE
            THAT Airgram HAS MADE NO AGREEMENTS, REPRESENTATIONS OR WARRANTIES
            OTHER THAN THOSE EXPRESSLY SET FORTH IN THESE TERMS.
          </Text>

          <Text as="h4">Limitation on Liability</Text>

          <Text>
            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL Airgram, ITS
            AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
            AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
            UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR
            USE, OR INABILITY TO USE, THE SERVICES, THE PLATFORM, OR ANY CONTENT
            OR PRODUCTS THEREIN, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT
            LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
            LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
            CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
            OTHERWISE, EVEN IF FORESEEABLE.
          </Text>

          <Text>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </Text>

          <Text as="h4">Indemnification</Text>

          <Text>
            You agree to defend, indemnify, and hold harmless Airgram, its
            affiliates, licensors, and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys’ fees) arising out
            of or relating to your violation of these Terms or your use of the
            Services, including, but not limited to, your Customer Data, any use
            of the Platform content, services, and products other than as
            expressly authorized in these Terms or your use of any information
            obtained from the Platform.
          </Text>

          <Text as="h4">Privacy</Text>

          <Text>
            We respect your privacy. Please see our Privacy Policy . By visiting
            this Site and accessing and using the Services, you consent to our
            collection and use of your personal information as set forth in the
            Privacy Policy.
          </Text>

          <Text as="h4">Miscellaneous</Text>

          <List>
            <ListItem>
              1. Survival. All provisions of this Agreement that by their nature
              should survive termination shall survive termination, including,
              without limitation, ownership provisions, warranty disclaimers,
              limitations of liability, and indemnification.
            </ListItem>
            <ListItem>
              2. No Waiver; Severability. No waiver of by Airgram of any term or
              condition set out in these Terms shall be deemed a further or
              continuing waiver of such term or condition or a waiver of any
              other term or condition, and any failure of the Company to assert
              a right or provision under these Terms shall not constitute a
              waiver of such right or provision. If any provision of these Terms
              is held by a court or other tribunal of competent jurisdiction to
              be invalid, illegal or unenforceable for any reason, such
              provision shall be eliminated or limited to the minimum extent
              such that the remaining provisions of the Terms will continue in
              full force and effect.
            </ListItem>

            <ListItem>
              3. Governing Law and Jurisdiction. All matters relating to the
              Services and these Terms and any dispute or claim arising
              therefrom or related thereto (in each case, including
              non-contractual disputes or claims), shall be governed by and
              construed in accordance with the internal laws of Hong Kong
              without giving effect to any choice or conflict of law provision
              or rule. Any legal suit, action, or proceeding arising out of, or
              related to, the Services and these Terms shall be decided
              exclusively by a court of competent jurisdiction located in Hong
              Kong. You waive any and all objections to the exercise of
              jurisdiction over you by such courts and to venue in such courts.
            </ListItem>

            <ListItem>
              4. Entire Agreement. These Terms, together with our Privacy
              Notice, shall constitute the entire agreement between you and
              Airgram concerning the Services. If you have any questions about
              these Terms, please contact us at
              <ChakraLink
                mx="5px"
                color="#7d848e"
                href="mailto:support@airgram.io"
              >
                support@airgram.io
              </ChakraLink>
              .
            </ListItem>
          </List>
        </VStack>
      </Center>
    </Layout>
  );
};

export default memo(TermsPage);
